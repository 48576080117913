<template>
  <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
    <a-form @submit="handleSubmit" :form="form">
      <a-form-item label="角色名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'title',
          {
            rules: [{ required: true, message: '角色名称' }],
            initialValue: adminGroupInfo.title
          },
        ]" placeholder="角色名称" />
      </a-form-item>
      <a-form-item label="权限节点" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-tree checkable :tree-data="treeData" v-model="checkedKeys" :selected-keys="selectedKeys"
          :replace-fields="replaceFields" @select="onSelect" @check="onCheck" />
      </a-form-item>
      <a-form-item label="状态" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-checkbox v-decorator="[
          'status',
          {
            valuePropName: 'checked',
            initialValue: adminGroupStatus,
          },
        ]">
          是否启用
        </a-checkbox>
      </a-form-item>
      <a-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-textarea rows="4" placeholder="请输入管理员描述信息" v-decorator="[
          'desc',
          {
            initialValue: adminGroupInfo.desc
          }
        ]" />
      </a-form-item>
      <a-form-item :wrapperCol="{ span: 24 }" style="text-align: center">
        <a-button htmlType="submit" :loading="submitLoading" type="primary">提交</a-button>
        <a-button style="margin-left: 8px" @click="routerback">返回</a-button>
      </a-form-item>
    </a-form>
  </a-card>
</template>

<script>
import { getAdminGrupInfo, nodeTreeList, adminGroupAdd, adminGroupUpdate } from '@/api/admin'
import { Tree as ATree } from 'ant-design-vue'

// const treeData = [
//   {
//     name: 'parent 1',
//     key: '0-0',
//     child: [
//       {
//         name: '张晨成',
//         key: '0-0-0',
//         disabled: true,
//         child: [
//           { name: 'leaf', key: '0-0-0-0', disableCheckbox: true },
//           { name: 'leaf', key: '0-0-0-1' }
//         ]
//       },
//       {
//         name: 'parent 1-1',
//         key: '0-0-1',
//         child: [{ key: '0-0-1-0', name: 'zcvc' }]
//       }
//     ]
//   }
// ]

export default {
  name: 'EditAdmin',
  components: {
    ATree
  },
  data() {
    return {
      description: '管理组编辑页面',
      treeData: [],
      replaceFields: {
        // children: 'children',
        title: 'name',
        key: 'id'
      },
      checkedKeys: [],
      selectedKeys: [], // 选中的节点，.sync修改
      value: 1,
      labelCol: {
        lg: { span: 7 },
        sm: { span: 7 }
      },
      wrapperCol: {
        lg: { span: 10 },
        sm: { span: 17 }
      },
      queryData: {
        id: this.$route.query.id
      },
      checkPass: true,
      adminGroupInfo: {},
      pageType: 'add', // 当前页面是新增(add),还是修改(edit)

      // form
      form: this.$form.createForm(this),
      adminGroupStatus: false,
      submitLoading: false
    }
  },
  created() {
    // 权限节点获取
    this.loadNodeTree().then(res => {
      this.treeData = res.data
    }).catch(error => {
      this.$notification.error({
        message: '错误',
        description: error.message
      })
    })
    // 修改时：获取管理组数据并选中节点
    const id = this.queryData.id
    if (id !== undefined) {
      this.pageType = 'edit'
      this.checkPass = false
      new Promise((resolve, reject) => {
        getAdminGrupInfo({ 'id': id }).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        const adminGroupInfo = res.data
        this.adminGroupInfo = adminGroupInfo
        if (adminGroupInfo.status === 1) {
          this.adminGroupStatus = true
        }
        // 设置已选中
        this.checkedKeys = adminGroupInfo.rules.split(',').map(Number)
      }).catch(error => {
        this.$notification.error({
          message: '错误',
          description: error.message
        })
        // 跳转回管理员管理页面
        setTimeout(() => {
          this.$router.back(-1)
        }, 2000)
      })
    }
  },
  methods: {
    loadNodeTree() {
      return new Promise((resolve, reject) => {
        nodeTreeList().then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      })
    },
    // handler
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          values.status = values.status ? 1 : -1
          values.rules = this.checkedKeys
          if (this.pageType === 'edit') {
            new Promise((resolve, reject) => {
              this.submitLoading = true
              values.id = this.queryData.id
              adminGroupUpdate(values).then(response => {
                if (response.status === 'success') {
                  resolve(response)
                } else {
                  reject(response)
                }
              })
            }).then(res => {
              this.$notification.success({
                message: res.status,
                description: res.message
              })
              // 跳转回管理员管理页面
              setTimeout(() => {
                this.$router.back(-1)
              }, 2000)
            }).catch(error => {
              this.$notification.error({
                message: '错误',
                description: error.message
              })
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            // 新增
            new Promise((resolve, reject) => {
              this.submitLoading = true
              adminGroupAdd(values).then(response => {
                if (response.status === 'success') {
                  resolve(response)
                } else {
                  reject(response)
                }
              })
            }).then(res => {
              this.$notification.success({
                message: res.status,
                description: res.message
              })
              // 跳转回管理员管理页面
              setTimeout(() => {
                this.$router.back(-1)
              }, 2000)
            }).catch(error => {
              this.$notification.error({
                message: '错误',
                description: error.message
              })
            }).finally(() => {
              this.submitLoading = false
            })
          }
        }
      })
    },
    onSelect(selectedKeys, info) {
      console.log('selected', selectedKeys, info)
    },
    onCheck(checkedKeys, info) {
      console.log('onCheck', checkedKeys, info)
    },
    routerback: function () {
      this.$router.back(-1)
    }
  }
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-card',{attrs:{"body-style":{ padding: '24px 32px' },"bordered":false}},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"角色名称","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'title',
        {
          rules: [{ required: true, message: '角色名称' }],
          initialValue: _vm.adminGroupInfo.title
        },
      ]),expression:"[\n        'title',\n        {\n          rules: [{ required: true, message: '角色名称' }],\n          initialValue: adminGroupInfo.title\n        },\n      ]"}],attrs:{"placeholder":"角色名称"}})],1),_c('a-form-item',{attrs:{"label":"权限节点","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-tree',{attrs:{"checkable":"","tree-data":_vm.treeData,"selected-keys":_vm.selectedKeys,"replace-fields":_vm.replaceFields},on:{"select":_vm.onSelect,"check":_vm.onCheck},model:{value:(_vm.checkedKeys),callback:function ($$v) {_vm.checkedKeys=$$v},expression:"checkedKeys"}})],1),_c('a-form-item',{attrs:{"label":"状态","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'status',
        {
          valuePropName: 'checked',
          initialValue: _vm.adminGroupStatus,
        },
      ]),expression:"[\n        'status',\n        {\n          valuePropName: 'checked',\n          initialValue: adminGroupStatus,\n        },\n      ]"}]},[_vm._v(" 是否启用 ")])],1),_c('a-form-item',{attrs:{"label":"备注","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'desc',
        {
          initialValue: _vm.adminGroupInfo.desc
        }
      ]),expression:"[\n        'desc',\n        {\n          initialValue: adminGroupInfo.desc\n        }\n      ]"}],attrs:{"rows":"4","placeholder":"请输入管理员描述信息"}})],1),_c('a-form-item',{staticStyle:{"text-align":"center"},attrs:{"wrapperCol":{ span: 24 }}},[_c('a-button',{attrs:{"htmlType":"submit","loading":_vm.submitLoading,"type":"primary"}},[_vm._v("提交")]),_c('a-button',{staticStyle:{"margin-left":"8px"},on:{"click":_vm.routerback}},[_vm._v("返回")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }